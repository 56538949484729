
import { AccountController } from "@/app/ui/controllers/AccountController";
import { Vue } from "vue-class-component";
import generateManifest from "./manifest-pdf";
import generateLabel from "./label-pdf";
import JsBarcode from "jsbarcode";
import { BaggingData, BaggingDataDetail } from "@/domain/entities/Bagging";

export default class Print extends Vue {
  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }

  printManifest(dataDetail: BaggingDataDetail) {
    // logo
    const element: any = document;
    const logo = element.getElementById("logo").src;

    JsBarcode("#barcode", dataDetail.bagVendorNo || dataDetail.bagNumber, {
      textAlign: "left",
      displayValue: false,
      fontSize: 16
    });
    const barcode = element.getElementById("barcode").src;

    // handle data
    const data: any = [];

    dataDetail.sttResult.forEach((item, index) => {
      data.push({
        no: index + 1,
        sttNo: "",
        product: "",
        commodity: "",
        koli: "",
        grossWeight: "",
        volumeWeight: "",
        originCity: "",
        destCity: "",
        sttNoRefExternal: ""
      });
      const sttNo = data[index].sttNo ? "\n\n" : "";
      const product = data[index].product ? "\n\n" : "";
      const commodity = data[index].commodity ? "\n\n" : "";
      const koli = data[index].koli ? "\n\n" : "";
      const grossWeight = data[index].grossWeight ? "\n\n" : "";
      const volumeWeight = data[index].volumeWeight ? "\n\n" : "";
      const originCity = data[index].originCity ? "\n\n" : "";
      const destinationCity = data[index].destCity ? "\n\n" : "";
      const sttNoRefExternal = data[index].sttNoRefExternal ? "\n\n" : "";

      data[index] = {
        ...data[index],
        sttNo: `${data[index].sttNo}${sttNo}${item.bagVendorNo ? item.sttNoRefExternal : item.sttNo}`,
        product: `${data[index].product}${product}${item.sttProductType}`,
        commodity: `${data[index].commodity}${commodity}${item.sttCommodityName}`,
        koli: `${data[index].koli}${koli}${item.sttTotalPiece}`,
        grossWeight: `${data[index].grossWeight}${grossWeight}${item.sttGrossWeight} Kg`,
        volumeWeight: `${data[index].volumeWeight}${volumeWeight}${item.sttVolumeWeight} Kg`,
        originCity: `${data[index].originCity}${originCity}${item.sttOriginCityId}`,
        destCity: `${data[index].destCity}${destinationCity}${item.sttDestinationCityId}`,
        sttNoRefExternal: `${data[index].sttNoRefExternal}${sttNoRefExternal}${item.sttNoRefExternal}`
      };
    });
    generateManifest(dataDetail, data, logo, barcode);
  }

  printLabel(data: BaggingData, bagVendorNo: string) {
    JsBarcode("#barcode", bagVendorNo || data.bagCode, {
      textAlign: "left",
      displayValue: false,
      fontSize: 16
    });
    const element: any = document;
    const logo = element.getElementById("logoLion").src;
    const barcode = element.getElementById("barcode").src;

    generateLabel(data, bagVendorNo, logo, barcode);
  }

  // role account user
  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }
}
